var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-list",attrs:{"id":"formEntryList"}},[_c('div',{staticClass:"list-entries",class:{ 'has-sections': _vm.sectionCount > 0 }},[_vm._l((_vm.schema),function(entry,index){return _c('div',{key:entry.key,ref:entry.key,refInFor:true,attrs:{"id":entry.key}},[(!_vm.submission && entry.ui.el == 'section' && entry.section - 1 == _vm.activeSection)?_c('div',{staticClass:"py-5 text-right px-5 pt-6"},[(_vm.activeSection != 1)?_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){_vm.activeSection = _vm.activeSection - 1;
            _vm.goToTop();}}},[_vm._v(" "+_vm._s(_vm.$t("t_back"))+" ")]):_vm._e(),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.validateForm();
            _vm.goToTop();}}},[_vm._v(" "+_vm._s(_vm.$t("t_next"))+" ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.submission || entry.section == undefined || entry.section == _vm.activeSection),expression:"submission || entry.section == undefined || entry.section == activeSection"}]},[_c('div',[(entry.ui.el == 'section')?_c('div',{staticClass:"section-header px-6 py-2 mt-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("t_section"))+" "+_vm._s(entry.section)+" "+_vm._s(_vm.$t("t_of"))+" "+_vm._s(_vm.sectionCount)+" ")]):_vm._e(),(entry.ui.el == 'section' && !entry.value)?_c('div',{staticClass:"section-header-border"}):_vm._e(),(!(entry.ui.el == 'section' && !entry.content.title && !entry.content.description))?_c('div',{staticClass:"entry-section",class:[entry.ui.el, entry.answer ? entry.answer.ui.el : '', _vm.getLang]},[_c(_vm.component(entry, entry.key, entry.section),{tag:"component"}),(entry.answer)?_c('div',[_c(_vm.component(entry.answer, entry.key, entry.section),{tag:"component"})],1):(entry.question)?_c('div',{staticClass:"answer-placeholder"}):_vm._e()],1):_vm._e()]),(
            !_vm.submission &&
            ((index + 1 == _vm.entryCount && entry.section == _vm.sectionCount) || (index + 1 == _vm.entryCount && !_vm.sectionCount))
          )?_c('v-card-actions',{staticClass:"py-5 px-5 pt-6"},[(!_vm.dialog)?_c('v-btn',{staticClass:"d-none d-sm-inline",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(_vm._s(_vm.$t("t_cancel")))]):_vm._e(),_c('v-spacer'),_c('div',[(_vm.activeSection > 1)?_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){_vm.activeSection = _vm.activeSection - 1;
                _vm.goToTop();}}},[_vm._v(" "+_vm._s(_vm.$t("t_back"))+" ")]):_vm._e(),(_vm.isAssignment)?_c('div',[_c('v-btn',{staticClass:"mr-3",on:{"click":function($event){return _vm.validateForm(false)}}},[_vm._v(_vm._s(_vm.$t("t_save")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.validateForm(true)}}},[_vm._v(_vm._s(_vm.$t("t_save_and_submit")))])],1):_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.validateForm(true)}}},[_vm._v(_vm._s(_vm.$t("t_submit")))])],1)],1):_vm._e()],1)])}),_c('v-alert',{ref:"alert-check-required",staticClass:"mt-1 mx-8 float-right alert-check-required",attrs:{"max-height":"38px","dense":"","type":"error","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("c_formviewer.t_check_required_fields"))+" ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }